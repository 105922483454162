import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Dispatch, iRootState, store } from '../../../store';

import UserMarkerGroup from './UserMarkerGroup';
import UserMarkerListItem from './UserMarkerListItem';

import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { IId } from '../../../models/types';

import apiConstants from '../../../constants/apiConstants';
import LocalStorageService from '../../../services/LocalStorageService';

const mapState = (state: iRootState) => ({
  map: state.infrastructureMap.map,
  users: state.infrastructureUsers.users,
  layers: state.infrastructureMap.layers
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getUsers: d.infrastructureUsers.getUsers,
    resetUsers: d.infrastructureUsers.resetUsers,
    setUsersListInMap: d.infrastructureUsers.setUsersListInMap
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

const UserMarkerList: FC<IProps> = ({
  users,
  map,
  layers,
  getUsers,
  setUsersListInMap,
  resetUsers
}) => {
  const { id } = map;
  const { isUsers } = layers;
  const { REACT_APP_DEV_URL } = process.env;
  const [connectionRef, setConnection] = useState<HubConnection>();

  const { Connected, Disconnected } = HubConnectionState;

  const url = `${REACT_APP_DEV_URL || ''}${apiConstants.prefix}`;
  const { accessToken } = LocalStorageService.getToken();

  const createHubConnection = () => {
    const connection = new HubConnectionBuilder()
      .configureLogging(1)
      .withUrl(`${url}hubs/moves`, {
        accessTokenFactory: () => {
          return `${accessToken}`;
        }
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => {
          return 5000;
        }
      })
      .build();

    setConnection(connection);
  };

  const signalRStop = () => {
    if (connectionRef) {
      connectionRef.stop();
    }
  };

  const signalRSubscribe = async (mapId: IId) => {
    // store.dispatch.infrastructureUsers.getUsers({
    //   birthday: '2021-09-04T00:00:00',
    //   descr: 'Разработчик web приложений',
    //   email: 'anyakov3@mts.ru',
    //   id: 25,
    //   immobilizedTimeMinutes: 90,
    //   lastname: 'Яковлев',
    //   mapColor: '#FFA080',
    //   name: 'Алексей',
    //   phone: '9778008551',
    //   protectionsList: [22, 17],
    //   resetPassword: false,
    //   role: 'SUPER_ADMIN',
    //   secondname: 'Николаевич',
    //   tabularNumber: '77777',
    //   title: 'Разработчик',
    //   username: '9778008551',
    //   workshiftBreakMinutes: 70,
    //   workshiftDurationHours: 1,
    //   position: {
    //     lat: 55.69190846220035,
    //     lon: 37.65957246474376
    //   }
    // });
    if (connectionRef) {
      if (connectionRef.state === Disconnected) {
        await connectionRef.start().then(() => {
          connectionRef.invoke('SubscribeToMap', mapId);
        });
        connectionRef.onreconnected(error => {
          connectionRef.invoke('SubscribeToMap', mapId);
        });
      } else {
        await connectionRef.invoke('SubscribeToMap', mapId);
      }
      connectionRef.on('UpdateInfo', payload => {
        // tslint:disable-next-line: no-console
        console.log(payload);
        getUsers({ payload, mapId });
      });
    }
  };

  const signalRUnsubscribe = (mapId: IId) => {
    if (connectionRef) {
      if (connectionRef.state === Connected) {
        connectionRef.off('UpdateInfo');
        // tslint:disable-next-line: no-console
        connectionRef.invoke('UnsubscribeFromMap', mapId).catch(err => console.error(err));
      }
    }
  };

  useEffect(() => {
    createHubConnection();
    return () => {
      signalRStop();
    };
  }, []);

  useEffect(() => {
    if (id) {
      // @ts-ignore
      setUsersListInMap(id).then(r => {
        // setUsersListInMap(id)
        signalRSubscribe(id);
      });
      return () => {
        signalRUnsubscribe(id);
      };
    }
  }, [id]);

  return (
    <UserMarkerGroup>
      {isUsers && users.map(user => <UserMarkerListItem key={user.id} user={user} />)}
    </UserMarkerGroup>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(UserMarkerList);
