/**
 * Сервис для работы с отчётами
 */

import axios, { AxiosRequestConfig } from 'axios';

import { pageSize } from '../constants/pagination';
import { IId } from '../models/types';

export interface IParams {
  ListOfEmployees: IId;
  AreaId: IId;
  From: string;
  To: string;
}

export interface IGetViolationParams {
  mapId: string;
  violationType: string;
  listOfEmployees: string[];
  from: string;
  to: string;
  pageIndex: number;
}

export interface IGetNotificationParams {
  listOfEmployees: string[];
  mapId: string;
  areaId: string;
  events: string[];
  // eventTypes: string;
  from: string;
  to: string;
  pageIndex: number;
}

export interface IGetWorkingTimeParams {
  listOfEmployees: string[];
  from: string;
  to: string;
  pageIndex: number;
}

export interface IGetUseProtectionParams {
  listOfEmployees: string[];
  from: string;
  to: string;
}

export interface IGetStayInAreaParams {
  mapId: string;
  areaId: string;
  listOfEmployees: string[];
  isCollapseTransitions: boolean;
  from: string;
  to: string;
  pageIndex: number;
}

export interface IGetPhysicalStateParams {
  listOfEmployees: string[];
  physType: string;
  from: string;
  to: string;
}

export interface IGetHeatMapFilter {
  mapId: string;
  userList: string[];
  from: string;
  to: string;
}

export interface IGetTracksFilter {
  users: string[];
  from: string;
  timeFrom: string;
  to: string;
  timeTo: string;
  PositionAggregationMeters: string;
  SplitByDays: boolean;
  SplitOnMapChange: boolean;
  SplitByEvents: boolean;
}

const url = '/reports';

const createCsvConfig = (filter: object): AxiosRequestConfig => ({
  params: { ...filter, reportFormat: 'CSV' },
  responseType: 'blob'
});

const ReportService = {
  getByAreas: (params: IParams) => axios.get(`${url}/zoneReport`, { params }),
  getViolations: (params: IGetViolationParams) =>
    axios.get(`${url}/violationReport/paged`, { params: { pageSize, ...params } }),
  getViolationsTypes: () => axios.get(`${url}/violationReport/violationTypes`),
  getCsvViolations: (params: IGetViolationParams) =>
    axios.get(`${url}/violationReport`, createCsvConfig(params)),
  getNotifications: (params: IGetNotificationParams) =>
    axios.get(`${url}/eventReport/paged`, { params: { pageSize, ...params } }),
  getCsvNotifications: (params: IGetNotificationParams) =>
    axios.get(`${url}/eventReport`, createCsvConfig(params)),
  getNotificationEvents: () => axios.get(`${url}/eventReport/events`),
  // getNotificationEventTypes: () => axios.get(`${url}/eventReport/eventTypes`),
  getWorkingTime: (params: IGetWorkingTimeParams) =>
    axios.get(`${url}/workshiftReport/paged`, { params: { pageSize, ...params } }),
  getCsvWorkingTime: (params: IGetWorkingTimeParams) =>
    axios.get(`${url}/workshiftReport`, createCsvConfig(params)),
  getUseProtection: (params: IGetUseProtectionParams) =>
    axios.get(`${url}/protectionReport/paged`, { params: { pageSize, ...params } }),
  getCsvUseProtection: (params: IGetUseProtectionParams) =>
    axios.get(`${url}/protectionReport`, createCsvConfig(params)),
  getStayInAreas: (params: IGetStayInAreaParams) =>
    axios.get(`${url}/zoneReport/paged`, { params: { pageSize, ...params } }),
  getCsvStayInAreas: (params: IGetStayInAreaParams) =>
    axios.get(`${url}/zoneReport`, createCsvConfig(params)),
  getPhysicalState: (params: IGetPhysicalStateParams) =>
    axios.get(`${url}/physReport/paged`, { params: { pageSize, ...params } }),
  getCsvPhysicalState: (params: IGetPhysicalStateParams) =>
    axios.get(`${url}/physReport`, createCsvConfig(params)),
  getPhysicalStateTypes: () => axios.get(`${url}/physReport/physTypes`),
  getHeatMap: (filter: IGetHeatMapFilter) =>
    axios.get(`/fulltrack/heatMap`, {
      params: { ...filter, format: 'IMAGEMAP' },
      responseType: 'arraybuffer'
    }),
  getJsonHeatMap: (filter: IGetHeatMapFilter) =>
    axios.get(`/fulltrack/heatMap`, { params: { ...filter, format: 'RAW', scale: 'LINEAR' } }),
  getTracks: (filter: IGetTracksFilter) =>
    axios.get(`/fulltrack/tracks`, { params: { ...filter, EventTimeToleranceSeconds: 0 } })
};
export default ReportService;
