import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import L from 'leaflet';
import { Map as LeafletMap, ImageOverlay } from 'react-leaflet';
import HeatmapLayer from 'react-leaflet-heatmap-layer';

// import getBlobImage from '../../../utils/getBlobImage';
import { IHeatMap } from '../../../models/reportHeatMap';
import { IReportHeatMapPoint } from '../../../models/types';
import { mapColor } from '../../../theme';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: spacing(5),
    width: '100%',
    height: 700,
    zIndex: 0
  }
}));

interface IProps {
  heatMap: IHeatMap;
}

const Map: FC<IProps> = ({ heatMap }) => {
  const classes = useStyles();

  const { map, planImg, data } = heatMap;
  const { preview, previewFormat, bbox } = map;

  // const imageOverlayUrl = getBlobImage(preview, previewFormat);

  const { center, bounds } = createCoord(bbox);

  return (
    <LeafletMap center={center} crs={L.CRS.Simple} zoom={4} className={classes.root}>
      <ImageOverlay url={planImg} bounds={bounds} zIndex={0} />
      <HeatmapLayer
        points={data}
        latitudeExtractor={(point: IReportHeatMapPoint) => point.y}
        longitudeExtractor={(point: IReportHeatMapPoint) => point.x}
        intensityExtractor={(point: IReportHeatMapPoint) => point.weightNormalized}
        radius={10}
        blur={1}
        max={0.01}
        maxZoom={100}
        minOpacity={0.2}
        //   const gradient = { 0.4: "blue", 0.8: "blue", 1.0: "blue" };
        // const gradient2 = { 0.4: "red", 0.8: "red", 1.0: "red" };
        // const gradient3 = { 0.4: "green", 0.8: "green", 1.0: "green" };
        // const gradient4 = { 0.4: "orange", 0.8: "orange", 1.0: "orange" };
        // gradient={{
        //   0.1: mapColor.normalBlackberry,
        //   0.3: mapColor.darkBlueberry,
        //   0.5: mapColor.darkestBanana,
        //   0.8: mapColor.darkestOrange,
        //   1.0: mapColor.mtsRed
        // }}
        gradient={{
          0.2: 'blue',
          0.5: 'green',
          0.7: 'yellow',
          1.0: 'red'
        }}
      />
    </LeafletMap>
  );
};

const createCoord = ([x, y, z, w]: [number, number, number, number]) => {
  const center = [w + y, z + x].map(coord => coord / 2) as [number, number];
  const bounds = [[w, x], [y, z]] as [[number, number], [number, number]];

  return { center, bounds };
};

export default Map;
